import React, { memo } from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

// sections for this page
import ScrollButton from '../components/ScrollButton';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const SurgetechnologyPage = memo(() => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "pic03-2.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, webpOptions: { quality: 100 })
          }
        }
      }
    `
  );
  const image = getImage(backgroundImage123);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead3"
    >
      <Layout fullMenu>
        <Seo
          title="Surge Technology"
          description="Digital transformations require investing across your organisation. Digital technology is key for todays organisations to be agile, competitive and efficient in their sectors. Surge focuses on web applications and integrating everyone into the cloud"
        />
        <ScrollButton />
        <article id="main2">
          <header>
            <h2>Digital and Cloud Outcomes</h2>
            <p>COMING SOON</p>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <section>
                <header>
                  <h4>Surging the ecosystem of technology</h4>
                  <p>
                    Moving your organisation into the future requires an
                    innovative approach. Surge Tech works together to provide
                    digital solutions to turn your processes into dynamic and
                    productive web applications.
                  </p>
                </header>
              </section>
            </div>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default SurgetechnologyPage;
